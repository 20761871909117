import React, { ReactElement, useEffect, useRef, useState } from "react";
import Logo from "@components/lawyerconsultation/logo";
import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { useTranslation } from "@hooks/useTranslation";
import colors from "@styles/variables/lawyerconsultationVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import ProxiedNumber from "@components/shared/proxiedNumber";
import { getHomepageHref } from "@lib/sharedUtils";
import { useCallCampaignContext } from "@hooks/useCallCampaignContext";
import CloseIcon from "@components/svg/closeIcon";

export default function Header({
    headerMessage,
    hideSticky,
    waitUntilValidateRecaptcha,
}: {
    headerMessage?: string | null | undefined;
    hideSticky?: boolean;
    waitUntilValidateRecaptcha?: boolean;
}): ReactElement {
    const { query } = useRouter();
    const { translate } = useTranslation();
    const { locale, defaultLocale } = useDomainContext();
    const { showPhoneNumber } = useCallCampaignContext();
    const { domain } = useDomainContext();
    const [headerHeight, setHeaderHeight] = useState<string>("60px");
    const [bannerActive, setBannerActive] = useState<boolean>(false);
    const header = useRef<HTMLHeadElement>(null);

    const bannerTextBlock = domain.textBlocks?.find(
        (textBlock) => textBlock.position === "banner",
    );

    useEffect(() => {
        let prevHeight = "0";
        const observer = new ResizeObserver(([headerEntry]) => {
            if (header.current) {
                const height =
                    headerEntry.borderBoxSize?.[0]?.blockSize.toFixed(0);
                if (height !== prevHeight) {
                    prevHeight = height;
                    setHeaderHeight(`${height}px`);
                }
            }
        });

        if (header.current) {
            observer.observe(header.current);
        }
        if (bannerTextBlock) {
            setTimeout(() => {
                setBannerActive(true);
            }, 500);
        }

        return () => {
            if (header.current) {
                observer.unobserve(header.current);
            }
        };
    }, []);

    let phoneNumberBlock = (
        <ProxiedNumber
            slogan={headerMessage ?? translate("SLOGAN")}
            phoneNumberColor="#fff"
            hideSticky={hideSticky}
            stickyStyle={{
                maxWidth: "1536px",
            }}
        />
    );

    if (waitUntilValidateRecaptcha) {
        phoneNumberBlock = showPhoneNumber ? (
            <ProxiedNumber
                slogan={headerMessage ?? translate("SLOGAN")}
                phoneNumberColor="#fff"
                hideSticky={hideSticky}
                stickyStyle={{
                    maxWidth: "1536px",
                }}
            />
        ) : (
            <></>
        );
    }

    return (
        <header className={styles.hdrWrapper} ref={header}>
            <div
                className={`${styles.hdrContainer} max-w-screen-2xl mx-auto py-3`}
            >
                <div
                    className={`${styles.hdr} flex justify-between items-center w-full`}
                >
                    <div className={`${styles.logo}`}>
                        <a href={getHomepageHref(query, locale, defaultLocale)}>
                            <Logo color={colors.lcSecondary} />
                        </a>
                    </div>

                    <div className={`flex relative ${styles["phoneNumber"]}`}>
                        <div className="flex items-center">
                            <span
                                className={`${styles.slogan} ml-3 text-xs font-normal`}
                            >
                                {phoneNumberBlock}
                            </span>
                        </div>
                    </div>
                </div>
                <style>{`:root {--header-height: ${headerHeight} }`}</style>
            </div>
            {bannerTextBlock && (
                <div
                    className={`${styles.banner} ${
                        bannerActive ? styles.active : ""
                    }`}
                    style={
                        {
                            "--banner-background-color":
                                domain?.config?.bannerBgColor,
                            "--banner-text-color":
                                domain?.config?.bannerTextColor,
                        } as React.CSSProperties
                    }
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: bannerTextBlock?.text,
                        }}
                        className={styles["banner-inner"]}
                    ></div>
                    <button
                        onClick={() => {
                            setBannerActive(false);
                        }}
                        type="button"
                        aria-label="Close"
                        className={styles["close-button"]}
                    >
                        <span className="close-icon">
                            <CloseIcon />
                        </span>
                    </button>
                </div>
            )}
        </header>
    );
}
